<template>
  <div class="ticket-fields">
    <template v-for="(field, fieldIndex) in fields">
      <div
        class="ticket-field"
        :class="{
          'ticket-field_group': isGroup(field),
        }"
        :key="`${field.id}_${fieldIndex}`"
      >
        <template v-if="isResource(field) && !field.value" />
        <div
          v-else-if="!isTable(field) || isTableHasValues(field)"
          class="ticket-field__label"
          @click="toggleGroup(field)"
        >
          {{ field.title }}
        </div>
        <template v-if="isTableHasValues(field)">
          <table-fields
            class="ticket-field__value"
            :columns="field.columns"
            :rows="field.rows"
          />
        </template>
        <template v-else-if="isGroup(field)">
          <transition>
            <ticket-fields
              class="ticket-field__value"
              :fields="field.childs"
            />
          </transition>
        </template>
        <template v-else-if="isCheckbox(field)">
          <div
            class="ticket-field__checkbox-list"
            v-for="(item, index) in field.values"
            :key="`checkbox-${index}`"
          >
            <esmp-checkbox :value="true" disabled>
              {{ item }}
            </esmp-checkbox>
          </div>
        </template>
        <div v-else class="ticket-field__value">
          <div v-html="field.value" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TableFields from './TableFields.vue';

export default {
  name: 'TicketFields',
  components: { TableFields },
  props: {
    fields: {
      type: Array,
      default: undefined,
    },
  },
  methods: {
    isResource(field) {
      return field.classType === 'resourceField';
    },
    isGroup(field) {
      return field.classType === 'group';
    },
    isTable(field) {
      return field.classType === 'table';
    },
    isTableHasValues(field) {
      return field.classType === 'table' && field.rows && field.rows.length
        && field.rows[0] && field.rows[0].some((i) => !i.isEmpty);
    },
    isCheckbox(field) {
      return field.classType === 'checkboxGroup';
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-field {
  &__label {
    font-weight: bold;
    font-size: 14px;
    @include for-size(phone-portrait-down) {
      font-size: 12px;
    }
  }
  &__value {
    margin: 4px 0 0 0;
    font-size: 14px;
    @include for-size(phone-portrait-down) {
      font-size: 12px;
    }
  }
  &_group {
    padding: 24px;
    border-radius: 12px;
    border: 1px rgba(232, 232, 238, 1) solid;
    background: #fff;
    &-gray {
      background: #F4F4F5;
    }
    @include for-size(phone-portrait-down) {
      padding: 8px;
      margin-top: 12px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
    @include for-size(phone-portrait-down) {
      margin-bottom: 8px;
    }
  }
}
</style>
